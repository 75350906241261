@import "~bootswatch/dist/superhero/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/superhero/bootswatch";

/* everything below here probably belongs in a different .scss file, really possibly along with
 * a variables.scss and a custom.scss as suggested at https://getbootstrap.com/docs/5.0/getting-started/webpack/#importing-precompiled-sass
 */

.twitter-svg,
.github-svg {
  height: 1rem;
}

.digitalocean-svg {
  height: 0.75rem;
}

pre {
  background-color: #45515f;
  border-radius: 0.45rem;
  padding: 0.25rem;
  color: white;
}

.codehilite pre {
  background-color: #0b1025;
}
.card {
  background-color: #45515f;
}

.card-header a:hover,
.card-footer a:hover {
  /* These look terrible when they fade to the default a:hover color */
  color: #df691a;
}

.page-header {
  /* padding: 1rem .5rem; */
  padding: 1rem;
  background-color: #2b3e50;
  margin-bottom: 0.25rem;
}

body {
  background: linear-gradient(#2b3e50, #000015);
  height: 100%;
  /*background: repeating-linear-gradient(#000020, #2B3E50);*/
}

header.blog-header {
  /*background-color: #45515f;*/
  /*text-align: center;*/
  padding-bottom: 1.25rem;
  margin-bottom: 2rem;
  border-bottom: 0.05rem solid #eee;
  padding-top: 1.25rem;

  /*background: linear-gradient(#566577, #2B3E50);*/
  background-color: 0;
}

header.blog-header .title {
  text-align: left;
}

header.blog-header .nav {
  justify-content: flex-end;
}

.blog-title {
  /* TODO: is this used? */
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: 400;
  color: white;
}
.blog-description {
  /* TODO: is this used? */
  font-size: 1.1rem;
  /*color: #DEC062;*/
  color: #df691a;
}

@media (min-width: 40em) {
  .blog-title {
    font-size: 3.5rem;
  }
}

aside {
  /*background-color: #E6ECF0;*/
  /*background-color: #4F99C4;*/
  /*background-color: #45515f;*/
  background-color: #2b3e50;
  padding-top: 0.5rem;
  border-radius: 0.5rem;
}

.sidebar-module {
  padding: 0 1rem 1rem;
}
.sidebar-module-inset {
  padding: 1rem;
  background-color: #f5f5f5;
  border-radius: 0.25rem;
}
.sidebar-module-inset p:last-child,
.sidebar-module-inset ul:last-child,
.sidebar-module-inset ol:last-child {
  margin-bottom: 0;
}

/* Pagination */
.blog-pagination {
  margin-bottom: 4rem;
}
.blog-pagination > .btn {
  border-radius: 2rem;
}

.blog-pagination .btn,
.blog-pagination .btn-outline-primary {
  color: #df691a;
  border-color: #df691a;
}

.blog-pagination .btn-outline-primary.disabled {
  color: #f0ad4e;
  border-color: #f0ad4e;
}

a {
  /* my main issue with bootstrap... so much has to be !important to override it */
  color: #df691a !important;

  &:active.btn.btn-outline-primary {
    border-color: #df691a !important;
    background-color: #df691a !important;
    color: #ff8905 !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

.btn:hover,
.btn-outline-primary:hover {
  background-color: #ff8905;
  color: #fff;
  border-color: #ff8905;
}

/*
   * Blog posts
   */

.blog-post {
  margin-bottom: 1rem;
  background-color: #2b3e50;
  padding: 1rem;
}
.blog-post-title {
  /* TODO: used on post list views, but not the actual post... can probably get rid of it.*/
  margin-bottom: 0.25rem;
  font-size: 2.5rem;
}
.blog-post-meta {
  /* TODO: used on post list views, but not the actual post... can probably get rid of it.*/
  margin-bottom: 1.25rem;
  color: #999;
}

/* main 2nd column */

  #column-2.default {
    border-radius: .5rem;
    padding: .5rem;
  } 

/* footer */
.blog-footer {
  padding: 2.5rem 0;
  text-align: center;
  // font-size: .7rem;
  margin-top: 0.5rem;
  background-color: rgba(0, 0, 0, 0);
}
.blog-footer p:last-child {
  margin-bottom: 0;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-color: #df691a;
  border-bottom-color: #2b3e50;
  border-radius: 0.25rem 0.25rem 0 0;
  background-color: #2b3e50;
}

.nav.nav-tabs,
.nav-tabs .nav-link:hover:not(.active) {
  border-color: #df691a;
  border-radius: 0.25rem 0.25rem 0 0;
}

.nav-tabs .nav-link {
  /*border-bottom-color: rgb(223, 105, 26);*/
  border-bottom-color: #df691a;
}

/*
   * Image gallery blocks - it's approaching time for some sass I think.
   */
/* .image-gallery__link {
     height: 250px;
   } */

/* .image-gallery__image {
     max-height: 250px;
   } */

/* merge a bunch of rules from how wagtail does its image gallery */
/* probably a ul */
.image-gallery .image-gallery__list {
  border-left: 1px solid #df691a;
  margin-bottom: 2em;
  list-style-type: none;
  font-style: normal;
  padding: 0;
  margin-top: 0;
  font-size: 0.95em;
}

.image-gallery .image-gallery__list::before,
.image-gallery__list::after {
  content: " ";
  display: table;
}

.image-gallery .image-gallery__list::after {
  clear: both;
}

/* probably an li */
.image-gallery .image-gallery__list-item {
  padding: 1.5em;
  height: 275px;
  text-align: center;
  margin-top: -1px;
  border: 1px solid #df691a; /* #e6e6e6; */
  border-left: 0;
  float: left;
  list-style-type: none;
}

.image-gallery__caption {
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: small;
  white-space: nowrap;
}

/* the <a> may want to rename this to something more generic? */
.image-gallery .image-gallery__link {
  color: inherit;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.image-gallery .image-gallery__link:hover {
  color: #df691a;
}

.image-gallery .image-gallery__wrapper {
  text-align: center;
  max-width: 250px;
  max-height: 250px;
  height: 200px;
  display: inline-block;
  line-height: 200px;
}

.image-gallery .image-gallery__image::before {
  display: inline-block;
  vertical-align: middle;
  content: "";
  height: 100%;
  margin-right: -0.25em;
}
.image-gallery .image-gallery__image {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  transition: border-color 0.2s ease;
  border: 3px solid #fff;
}

.image-gallery .image-gallery__image:hover {
  border: 3px solid #df691a;
}

/* On tap stuff - should move to its own scss */
.card.currently-on-tap {
  min-height: 275px; // TODO: convert to rem
}
/* srm colors for background */
.bg-srm-1 {
  background-color: #ffe699;
}
.bg-srm-2 {
  background-color: #ffd878;
}
.bg-srm-3 {
  background-color: #ffca5a;
}
.bg-srm-4 {
  background-color: #ffbf42;
}
.bg-srm-5 {
  background-color: #fbb123;
}
.bg-srm-6 {
  background-color: #f8a600;
}
.bg-srm-7 {
  background-color: #f39c00;
}
.bg-srm-8 {
  background-color: #ea8f00;
}
.bg-srm-9 {
  background-color: #e58500;
}
.bg-srm-10 {
  background-color: #de7c00;
}
.bg-srm-11 {
  background-color: #d77200;
}
.bg-srm-12 {
  background-color: #cf6900;
}
.bg-srm-13 {
  background-color: #cb6200;
}
.bg-srm-14 {
  background-color: #c35900;
}
.bg-srm-15 {
  background-color: #bb5100;
}
.bg-srm-16 {
  background-color: #b54c00;
}
.bg-srm-17 {
  background-color: #b04500;
}
.bg-srm-18 {
  background-color: #a63e00;
}
.bg-srm-19 {
  background-color: #a13700;
}
.bg-srm-20 {
  background-color: #9b3200;
}
.bg-srm-21 {
  background-color: #952d00;
}
.bg-srm-22 {
  background-color: #8e2900;
}
.bg-srm-23 {
  background-color: #882300;
}
.bg-srm-24 {
  background-color: #821e00;
}
.bg-srm-25 {
  background-color: #7b1a00;
}
.bg-srm-26 {
  background-color: #771900;
}
.bg-srm-27 {
  background-color: #701400;
}
.bg-srm-28 {
  background-color: #6a0e00;
}
.bg-srm-29 {
  background-color: #660d00;
}
.bg-srm-30 {
  background-color: #5e0b00;
}
.bg-srm-31 {
  background-color: #5a0a02;
}
.bg-srm-32 {
  background-color: #600903;
}
.bg-srm-33 {
  background-color: #520907;
}
.bg-srm-34 {
  background-color: #4c0505;
}
.bg-srm-35 {
  background-color: #470606;
}
.bg-srm-36 {
  background-color: #440607;
}
.bg-srm-37 {
  background-color: #3f0708;
}
.bg-srm-38 {
  background-color: #3b0607;
}
.bg-srm-39 {
  background-color: #3a070b;
}
.bg-srm-40 {
  background-color: #36080a;
}
